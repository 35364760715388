/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Text from '@ingka/text';
import _ from 'lodash';
import { isNullEmptyOrUndefined } from '../../../common/services/commonService.js';
import CustomNews from '../createUnit/customNews/CustomNews';
import './ViewUnitDetails.css';
import { appRoutes } from '../../../common/constants/constant';
import AppBarIcons from '../../../common/components/appBarIcons/AppBarIcons';
import { KeyVal } from '../createUnit/unitDetails/UnitDetails.js';
import useCallApi from '../../../common/services/apiService.js';
import { useAppDispatch } from '../../../store/hooks';
import { setLoader } from '../../../store/reducers/create-proj-reducer';
import { useTranslate } from '../../../common/services/translationService.js';
import { setInitUnitData } from '../../../store/reducers/create-unit-reducer';

const ViewUnitDetails = () => {
  const location: any = useLocation();
  const [unitData, setUnitdata] = useState<any>(null);
  const [rocData, setRocData] = useState<KeyVal[]>([]);
  const [pcData, setPriceClassData] = useState<KeyVal[]>([]);

  const customUnitDetailsTitle = useTranslate('customUnit.details.title');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { get } = useCallApi();

  const getROC = async () => {
    get('rocs', (data: any) => {
      const rocList: KeyVal[] = [];
      data?.forEach((roc: any) => {
        rocList?.push({
          key: roc?.rocName,
          value: roc?.rocNo,
        });
      });
      setRocData(rocList);
    });
  };

  const getPriceClass = () => {
    get('price-level', (data: any) => {
      const priceClassList: KeyVal[] = [];
      data?.priceClass?.forEach((roc: any) => {
        priceClassList?.push({
          key: roc?.pcName,
          value: roc?.pcNo,
        });
      });
      setPriceClassData(priceClassList);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoader(true));
      try {
        await Promise.all([getROC(), getPriceClass()]);
      } catch (error) {
        // handle error
      } finally {
        dispatch(setLoader(false));
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const data = location?.state;
    const rocItem = rocData?.find((rocEntry: KeyVal) => rocEntry?.value === data?.roc);
    const pcItem = pcData?.find((pcEntry: KeyVal) => pcEntry?.value === data?.priceClass);
    const detailsTab = {
      Name: data?.customUnitName,
      Hfb: data?.hfbNo,
      Pra: data?.praNo,
      Pa: data?.paNo,
      PiaLink: data?.unitUrl,
      Comments: data?.comment,
      unitState: data?.unitState,
      roc: rocItem,
      priceClass: pcItem,
      Images: data?.unitImageUrl || [],
      ImageNames: data?.unitImageList || [],
    };
    const itemsTab = {
      importedItems: data?.customUnitItemsDetails,
    };
    const generic = {
      unitNo: data?.customUnitNo,
      sellingPrice: data?.unitRsp,
      currency: data?.unitCurrency,
      unitId: data?.customUnitId,
      unitType: data?.customUnitType,
      canEdit: data?.canEdit,
      userId: data?.userId,
    };
    if (!isNullEmptyOrUndefined(data) && rocData.length > 0 && pcData.length > 0) {
      setUnitdata({
        detailsTab,
        itemsTab,
        generic,
      });
    }
    const initDetailsVal = _.cloneDeep(detailsTab);
    delete initDetailsVal.ImageNames;
    delete initDetailsVal.Images;
    initDetailsVal.unitState = { key: data?.unitState, value: data?.unitState };
    dispatch(setInitUnitData({
      detailsTab: initDetailsVal,
      itemsTab,
    }));
  }, [rocData, pcData]);

  const handleCloseModal = () => {
    navigate(
      appRoutes.viewUnit,
      {
        state: {
          isVuDetails: true,
        },
      },
    );
  };

  return (
    <div className="vudParent">
      {!isNullEmptyOrUndefined(unitData) ? (
        <>
          <header className="headerContainer">
            <Text className="icPageTitle">{customUnitDetailsTitle}</Text>
            <div className="iconsWrapper">
              <AppBarIcons closeFn={handleCloseModal} />
            </div>
          </header>
          <CustomNews
            unitData={unitData}
            detailsTab={unitData?.detailsTab}
            itemsTab={unitData?.itemsTab}
            generic={unitData?.generic}
          />
        </>
      ) : null}
    </div>
  );
};

export default ViewUnitDetails;
