/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the initial state using that type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialState: any = {
  createUnitData: {
    detailsTab: {
      Name: '',
      Hfb: '',
      Pra: '',
      Pa: '',
      PiaLink: '',
      Comments: '',
      unitState: '',
      roc: '',
      priceClass: '',
      Images: <any>[],
    },
    itemsTab: { importedItems: [] },
  },
  initUnitData: {
    detailsTab: {
      Name: '',
      Hfb: '',
      Pra: '',
      Pa: '',
      PiaLink: '',
      Comments: '',
      unitState: '',
      roc: '',
      priceClass: '',
      Images: <any>[],
    },
    itemsTab: { importedItems: [] },
  },
  unitNameValid: { status: true, message: '' },
  urlValid: { status: true, message: '' },
  commentsValid: true,
  submitValid: false,
  imgChangeFlag: false,

};

export const Reducer = createSlice({
  name: 'customUnitReducer',
  initialState,
  reducers: {
    setUnitSubmitValid: (state, action: PayloadAction<boolean>) => {
      state.submitValid = action.payload;
    },
    setUrlValid: (state, action: PayloadAction<object>) => {
      state.urlValid = { ...action.payload };
    },
    setUnitNameValid: (state, action: PayloadAction<object>) => {
      state.unitNameValid = { ...action.payload };
    },
    setCommentsValid: (state, action: PayloadAction<boolean>) => {
      state.commentsValid = action.payload;
    },
    setImageChanged: (state, action: PayloadAction<boolean>) => {
      state.imgChangeFlag = action.payload;
    },
    createUnit: (state, action: PayloadAction<[]>) => {
      state.createUnitData = { ...action.payload };
    },
    setInitUnitData: (state, action: PayloadAction<any>) => {
      state.initUnitData = { ...action.payload };
    },
    createUnitFullReset: (state) => {
      state.createUnitData = { ...initialState.createUnitData };
      state.urlValid = { ...initialState.urlValid };
      state.unitNameValid = { ...initialState.unitNameValid };
      state.submitValid = false;
      state.initUnitData = { ...initialState.createUnitData };
      state.imgChangeFlag = false;
    },
  },
});

export const {
  setUnitNameValid,
  setUrlValid,
  setCommentsValid,
  setUnitSubmitValid,
  createUnit,
  createUnitFullReset,
  setImageChanged,
  setInitUnitData,
} = Reducer.actions;

export default Reducer.reducer;
